<template>
  <div>
    <b-container class="base-container-x pt-2" style="min-height:400px;background-color:#fff">
      <b-row class="mb-5 justify-content-center">
        <b-col cols="12">
          <StepFormRegister :currentStep="this.currentStep" :titleListSteps="this.titleListSteps" />
        </b-col>
        <b-col xl="9" lg="9" sm="12" md="12" class="border rounded py-4 py-md-5 border-light-gray">
          <h3 class="content-header-title text-primary-dark">{{ $t('Yoga Information') }}</h3>
          <div class="mt-4">
            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
              <b-form-group>
                <label for="apply_for" class="text-primary-dark font-weight-600">{{ $t('Applying for') }} <span class="text-danger-light">*</span></label>
                <multiselect
                 v-model="form.teacher_apply_type"
                 :options="teacher_type_apply_options"
                 :multiple="true"
                 :placeholder="$t('Type of Teacher Registration')"
                 :selectedLabel="$t('Selected')"
                 :deselectLabel="$t('Press enter to remove')"
                 :selectLabel="$t('Press enter to select')"
                 label="teacher_apply_type_name"
                 track-by="teacher_apply_type"
                 ></multiselect>
              </b-form-group>
              <b-form-group>
                <div>
                  <label for="level_of_registration" class="text-primary-dark font-weight-600">{{ $t('Level of Registration') }} <span class="text-danger-light">*</span></label>
                  <b-form-checkbox v-model="levelTypechecked" name="check-button" switch class="ml-2 text-right d-inline">
                    <b>{{ levelTypechecked ? $t('Lifetime') : $t('Annual') }}</b> <router-link :to="{ name: 'feeDetails' }" target="_blank"><b-icon icon="question-circle" :title="$t('Fee')" aria-label="Help"></b-icon></router-link>
                  </b-form-checkbox>
                </div>
                <multiselect
                 v-model="form.teacher_level"
                 :options="teacher_level_options"
                 :multiple="true"
                 :placeholder="$t('Level of Registration')"
                 :selectedLabel="$t('Selected')"
                 :deselectLabel="$t('Press enter to remove')"
                 :selectLabel="$t('Press enter to select')"
                 label="teacher_level_name"
                 track-by="teacher_level"
                 ></multiselect>
              </b-form-group>
              <b-form-group>
                <label for="other_level" class="text-primary-dark font-weight-600">{{ $t('Other Level of Registration (If any)') }} <b-icon icon="question-circle" id="tooltip-level-register" aria-label="Help"></b-icon>
                  <b-tooltip target="tooltip-level-register">{{ $t('In case if you cant find your level of registration provide above, Please specify here') }}</b-tooltip>
                </label>
                <b-form-textarea
                  id="other_level"
                  :placeholder="$t('Other Level of Registration (If any)')"
                  v-model="form.other_level"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>
              <b-form-group>
                <label for="experience_yoga_teaching" class="text-primary-dark font-weight-600">{{ $t('How long have you been teaching yoga?') }} <span class="text-danger-light">*</span></label>
                <b-form-select id="experience_yoga_teaching" v-model="form.teacher_experience" :options="optionForm.teacher_experience_years" value-field="id" text-field="name">
                  <template #first>
                    <b-form-select-option :value="null" disabled>{{ $t('Click Here') }}</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
              <b-form-group>
                <label for="after_graduated_teaching" class="text-primary-dark font-weight-600">{{ $t('Have you been teaching yoga classes at least 3,000 - 4,500 hours after graduated?') }} <span class="text-danger-light">*</span></label>
                <b-form-select id="after_graduated_teaching"  v-model="form.is_teacher_been_teaching_least" :options="optionForm.is_teacher_been_teaching_least" value-field="value" text-field="name">
                  <template #first>
                    <b-form-select-option :value="null" disabled>{{ $t('Click Here') }}</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
              <b-form-group>
                <label for="yoga_style" class="text-primary-dark font-weight-600">{{ $t('Style of Yoga') }} <span class="text-danger-light">*</span></label>
                 <multiselect
                 v-model="form.style_of_yoga"
                  :options="teacher_style_of_yoga_options"
                  :multiple="true"
                  :placeholder="$t('Style of Yoga')"
                  :selectedLabel="$t('Selected')"
                  :deselectLabel="$t('Press enter to remove')"
                  :selectLabel="$t('Press enter to select')"
                  label="style_of_yoga_name"
                  track-by="style_of_yoga"
                  ></multiselect>
              </b-form-group>
              <b-form-group>
                <label for="description_yoga_style" class="text-primary-dark font-weight-600">{{ $t('Description of Your Yoga Style') }}</label>
                <b-form-textarea
                  id="description_yoga_style"
                  :placeholder="$t('Enter description')"
                  v-model="form.description_style_of_yoga"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>
              <b-row class="justify-content-center mt-4">
                <b-button type="reset" @click="onPrevious" pill class="btn-default text-primary-dark font-weight-bold px-5">{{ $t('Previous') }}</b-button>
                <b-button type="submit" :disabled="isLoading" pill class="btn-primary-dark ml-3 font-weight-bold px-5"><b-spinner small class="mr-3" v-if="isLoading"  label="Loading..."/>{{ $t('Next') }}</b-button>
              </b-row>
            </b-form>
            </ValidationObserver>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>

import StepFormRegister from '../../components/form/StepFormRegister'
import Api from '../../api/api'
import Multiselect from 'vue-multiselect'

export default {
  name: 'TeacherRegisterYogaAndFaculty',
  title: ' | Teacher register TeacherRegisterYoga',
  components: {
    StepFormRegister,
    Multiselect
  },
  data () {
    return {
      currentStep: 2,
      titleListSteps: [this.$t('Start'), this.$t('Teacher info'), this.$t('Yoga info'), this.$t('Document'), this.$t('Payment'), this.$t('Finished')],
      form: {
        id: null,
        teacher_apply_type: [],
        teacher_level: [],
        other_level: null,
        style_of_yoga: null,
        description_style_of_yoga: null,
        teacher_experience: null,
        is_teacher_been_teaching_least: null
      },
      levelTypechecked: true,
      optionForm: {
        teacher_background: [],
        teacher_experience_years: [],
        teacher_levels: [],
        teacher_type_apply: [],
        teacher_types: [],
        teacher_style_of_yoga: [],
        is_teacher_been_teaching_least: [
          { value: true, name: this.$t('Yes') },
          { value: false, name: this.$t('No') }
        ]
      },
      teacher_type_apply_options: [],
      teacher_level_options: [],
      teacher_level_temp_options: [],
      teacher_style_of_yoga_options: [],
      isLoading: false
    }
  },
  watch: {
    levelTypechecked (newLevelTypechecked, oldLevelTypechecked) {
      if (newLevelTypechecked) {
        this.setOptionTeacherLevel(true)
      } else {
        this.setOptionTeacherLevel(false)
      }
    }
  },
  async mounted () {
    window.scrollTo(0, 0)
    const user = await this.$store.getters.userProfile
    await this.$store.dispatch('getTeacher', { user: user.id })
    this.toggleBurgerButtonClass('addClass', 'bars-bg-gray')
    const teacherId = localStorage.getItem('teacherId')
    if (teacherId) {
      this.getTeacher(teacherId)
    } else {
      this.$router.push({ name: 'teacherRegisterInformation' })
    }
  },
  computed: {
    teacherId () {
      return this.$store.getters.teacherId
    }
  },
  methods: {
    toggleBurgerButtonClass (addRemoveClass, className) {
      const burger = document.querySelector('.bm-burger-button')
      if (addRemoveClass === 'addClass') {
        burger.classList.add(className)
      } else {
        burger.classList.remove(className)
      }
    },
    onSubmit () {
      this.$refs.form.validate().then(result => {
        if (result) {
          this.updateTeacher()
        }
      })
    },
    updateTeacher () {
      this.isLoading = true
      Api.updateTeacher(this.form)
        .then((response) => {
          this.isLoading = false
          this.$router.push({ name: 'teacherRegisterDocument' })
        })
        .catch(() => {
          this.isLoading = false
          const textError = this.$t('Unsuccessfully register teacher')
          this.$notify({
            group: 'error',
            title: this.$t('Register teacher'),
            text: textError
          })
        })
    },
    formDataTeacher () {
      Api.formDataTeacher().then(response => {
        if (response.data) {
          this.optionForm = { ...this.optionForm, ...response.data }
          const teacherApplyTypeOptions = []
          const teacherLevelOptions = []
          const teacherStyleOfYogaOptions = []
          const teacherId = this.form.id
          this.optionForm.teacher_type_apply.forEach(item => {
            teacherApplyTypeOptions.push({
              teacher: teacherId,
              teacher_apply_type: item.id,
              teacher_apply_type_name: item.name
            })
          })
          this.teacher_type_apply_options = teacherApplyTypeOptions
          this.optionForm.teacher_levels.forEach(item => {
            teacherLevelOptions.push({
              teacher: teacherId,
              teacher_level: item.id,
              teacher_level_name: item.name,
              level_type: item.level_type
            })
          })
          this.teacher_level_options = teacherLevelOptions
          this.teacher_level_temp_options = teacherLevelOptions
          if (this.form.teacher_level.length > 0) {
            if (this.form.teacher_level[0].level_type === 'Lifetime') {
              this.setOptionTeacherLevel(true)
            } else {
              this.levelTypechecked = false
              this.setOptionTeacherLevel(false)
            }
          } else {
            this.setOptionTeacherLevel(true)
          }
          this.optionForm.teacher_style_of_yoga.forEach(item => {
            teacherStyleOfYogaOptions.push({
              teacher: teacherId,
              style_of_yoga: item.id,
              style_of_yoga_name: item.name
            })
          })
          this.teacher_style_of_yoga_options = teacherStyleOfYogaOptions
        }
      })
    },
    setOptionTeacherLevel (levelTypechecked) {
      if (levelTypechecked) {
        this.teacher_level_options = this.teacher_level_temp_options.filter(level => level.level_type === 'Lifetime')
        this.form.teacher_level = this.form.teacher_level.filter(level => level.level_type === 'Lifetime')
      } else {
        this.teacher_level_options = this.teacher_level_temp_options.filter(level => level.level_type === 'Annual')
        this.form.teacher_level = this.form.teacher_level.filter(level => level.level_type === 'Annual')
      }
    },
    getTeacher (id) {
      Api.teacherDetail({ id: id }).then(response => {
        if (response) {
          for (const property in this.form) {
            if (property in response.data) {
              this.form[property] = response.data[property]
            }
          }
          this.formDataTeacher()
        }
      })
    },
    onPrevious () {
      this.$router.push({ name: 'teacherRegisterInformation' })
    }
  },
  destroyed () {
    this.toggleBurgerButtonClass('removeClass', 'bars-bg-gray')
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
